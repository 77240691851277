import React, {lazy, Suspense} from "react";
import "bootstrap/dist/css/bootstrap.min.css"
import "./css/global.scss"
import "./css/theme.scss"
import "./css/captions.css"
import "./css/inline.css"
import {Route, Routes} from "react-router-dom";
import {Page} from "./common/types";
import Layout from "./layout/Layout";
import IndexPage from "./pages/IndexPage";
import {
    BagFill,
    BoxArrowLeft,
    CardChecklist,
    ClipboardCheckFill,
    CupHotFill,
    PaletteFill,
    PersonFill, QuestionCircleFill, TelephoneFill
} from "react-bootstrap-icons";

const Profile = lazy(() => import("./pages/profile/Profile"));
const Catalog = lazy(() => import("./pages/catalog/Catalog"));
const NotFound = lazy(() => import("./pages/notFound/NotFound"));
const Carts = lazy(() => import("./pages/carts/Carts"));
const Orders = lazy(() => import("./pages/orders/Orders"));
const Logout = lazy(() => import("./pages/Logout"));
const Order = lazy(() => import("./pages/order/Order"));
const ContactsPage = lazy(() => import( "./pages/contacts/ContactsPage"));
const HelpPage = lazy(() => import( "./pages/help/HelpPage"));
const AuthPage = lazy(() => import( "./pages/auth/AuthPage"));
const AuthAdmin = lazy(() => import( "./pages/auth/AuthAdmin"));
const AuthBase = lazy(() => import( "./pages/auth/AuthBase"));
const RedirectPage = lazy(() => import( "./pages/auth/RedirectPage"));

const pages: Page[] = [
    {
        id: 5,
        path: "profile",
        name: "Личный кабинет",
        element: <Profile/>,
        icon: <PersonFill/>
    },
    {
        id: 0,
        path: "catalog",
        name: "Меню",
        element: <Catalog/>,
        icon: <CupHotFill/>
    },
    {
        id: 1,
        path: "cart",
        name: "Корзина",
        element: <Carts/>,
        icon: <BagFill/>
    },
    {
        id: 2,
        path: "orders",
        name: "Заказы",
        element: <Orders/>,
        icon: <ClipboardCheckFill/>
    },
    {
        id: 2,
        path: "contacts",
        name: "Контакты",
        element: <ContactsPage/>,
        icon: <TelephoneFill/>
    },
    {
        id: 3,
        path: "orders/:divisionId/:id",
        name: "Заказ",
        element: <Order/>,
        icon: <CardChecklist/>,
        notInMenu: true
    },
    {
        id: 4,
        path: "logout",
        name: "Выход",
        notInMenu: true,
        element: <Logout/>,
        icon: <BoxArrowLeft/>
    },
    {
        id: 5,
        path: "help",
        name: "Справка",
        notInMenu: true,
        element: <HelpPage/>,
        icon: <QuestionCircleFill/>
    }
]

function App() {
    return (
        <Suspense fallback={<></>}>
            <Routes>
                <Route path="/" element={<Layout pages={pages}/>}>
                    <Route index element={<IndexPage/>}/>
                    {
                        pages.map(p => {
                            return <Route key={p.path} path={p.path} element={p.element}/>
                        })
                    }
                    <Route path="*" element={<NotFound/>}/>
                </Route>
                <Route path="auth" element={<AuthPage/>}>
                    <Route index element={<AuthBase/>}/>
                    <Route path="redirect" element={<RedirectPage/>}/>
                    <Route path="secure-auth" element={<AuthAdmin/>}/>
                </Route>
            </Routes>
        </Suspense>
    );
}

export default App;
