import React, {useCallback, useState} from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import SvgButton from "../UI/button/SVGButton";
import {NavLink} from "react-router-dom";
import {RFCWithAnyProps, Page} from "../../common/types";
import {closeMenuSvg, mainMenuButtonSvg} from "../../assets/svg.module";
import MenuItem from "../menu/MenuItem";
import ListGroup from "react-bootstrap/ListGroup";
import {useAppSelector} from "../../redux/hooks";
import {clearTokensLocalStorage} from "../../api/axiosInstances";
import Badge from "../badge/Badge";
import {
    countActiveOrders,
    selectCountNotifications,
    selectCountUnreadNotifications
} from "../../redux/selectors/selectors";
import Notifications from "../notifications/Notifications";

interface IProps {
    pages: Page[];
}

const RightMenu: RFCWithAnyProps<IProps> = ({pages, ...props}) => {
    const countOrders = useAppSelector(countActiveOrders)
    const countNotifications = useAppSelector(state => selectCountUnreadNotifications(state))
    const [show, setShow] = useState(false);
    const logOutPageProps = pages.find(p => p.path === 'logout')

    const handleClose = useCallback(() => setShow(false), [])

    const handleShow = useCallback(() => setShow(true), [])

    return (
        <>
            <SvgButton
                onClick={handleShow}
                svg={mainMenuButtonSvg}
                className="btn btn-white menu_btn">
                <Badge count={countNotifications} position={"relative"} style={{right: 15, top: 5, zIndex: 10}}/>
            </SvgButton>
            <Offcanvas
                show={show}
                onHide={handleClose}
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasMenu"
                aria-labelledby="offcanvasMenuLabel"
                {...props}>
                <Offcanvas.Header>
                    <Offcanvas.Title as={"h3"}>Меню</Offcanvas.Title>
                    <SvgButton
                        svg={closeMenuSvg}
                        onClick={handleClose}
                        aria-label="Close"
                        className="btn p-0 btn-white menu_btn btnMenuClose"/>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Notifications countNotifications={countNotifications} isMobile={true} onClickNotification={handleClose}/>
                    <ListGroup as="ul" className="mainMenuList">
                        {
                            pages.filter(i => !(i.notInMenu ?? false)).map(p => {
                                if (p.path.includes("orders"))
                                    return <NavLink key={p.path} to={p.path}
                                                    className={({isActive}) => (isActive ? 'active' : 'inactive')}
                                                    onClick={handleClose}>
                                        <MenuItem data={{name: p.name, path: p.path, icon: p.icon}}
                                                  countNotifications={countOrders}/>
                                    </NavLink>

                                return <NavLink key={p.path} to={p.path}
                                                className={({isActive}) => (isActive ? 'active' : 'inactive')}
                                                onClick={handleClose}>
                                    <MenuItem data={{name: p.name, path: p.path, icon: p.icon}}/>
                                </NavLink>
                            })
                        }
                    </ListGroup>
                </Offcanvas.Body>
                <div className="offcanvas-footer">
                    <div className="d-flex justify-content-end">
                        {
                            logOutPageProps && <div className="logOutBtn">
                                <MenuItem data={{
                                    name: logOutPageProps?.name,
                                    path: logOutPageProps?.path,
                                    icon: logOutPageProps?.icon
                                }} onCLick={async () => {
                                    await clearTokensLocalStorage()
                                    window.location.replace('/auth')
                                }}/>
                            </div>
                        }
                    </div>
                </div>
            </Offcanvas>
        </>
    )
}

export default RightMenu;
