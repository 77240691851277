import ReferenceDto from "../../common/dto/ReferenceDto";
import ASOPDto from "../../common/dto/ASOPDto";
import AbstractAvailableBenefitDto, {IAvailableBenefit} from "./AbstractAvailableBenefit";
import Nomenclature from "../nomenclature/Nomenclature";
import {IAvailablePayment} from "../payment";

export default interface OrderDto extends ReferenceDto {
    number: string;
    numberForPrint: string;
    date: Date;
    status: OrderStatus;
    statusName: string;
    deliveryDate: string;
    clientRef: string;
    clientName: string;
    orderDate: Date;
    mealRef: string;
    mealName: string;
    deliveryPointRef: string;
    deliveryPointName: string;
    cancellationReason: string;
    comment: string;
    deliveryTime: string;
    amount: number;
    orderAmount: number;
    benefitsAutoAmount: number
    benefitsTotalAmount: number
    benefitsManuallyAmount: number
    benefitsQuantitativeAmount: number
    usedBenefitsAmount: number;
    items: OrderItem[];
    availableBenefits: OrderAvailableBenefit[];
    availableStandardPayments: OrderAvailablePayments[]
    availableQuantitativeBenefits: OrderAvailableQuantitativeBenefit[]
    nomenclatureBenefits: OrderNomenclatureBenefit[]
    usedBenefits: OrderUsedBenefit[];
    usedPayments: OrderUsedPaymentDto[];
    standardPaymentsAmount: number
    amountRemaining: number
    allowedUntil: Date
    allowed: boolean
}

export interface OrderItem extends ASOPDto {
    ref: string;
    nomenclature: Nomenclature;
    nomenclatureRef: string;
    nomenclatureName: string;
    price: number;
    quantity: number;
    amount: number;
    complex: boolean;
    complexItem: boolean;
    complexSetId: string;
    cartComplexSetItems:[]
}

export interface OrderAvailablePayments extends IAvailablePayment {}

export interface OrderNomenclatureBenefit extends INomenclature {
    benefitsTypeRef: string
    benefitsTypeName: string
}

export interface OrderAvailableBenefit extends AbstractAvailableBenefitDto {}

export interface OrderAvailableQuantitativeBenefit extends IAvailableBenefit {
    nomenclatures: INomenclature[]
}

export interface OrderUsedBenefit extends ASOPDto {
    benefitsTypeRef: string;
    benefitsTypeName: string;
    amount: number;
    quantity: number;
    compensationPayment: boolean;
}

export interface OrderUsedPaymentDto extends ASOPDto {
    paymentTypeRef: string;
    paymentTypeName: string
    amount: number;
}

export interface OrderPayment extends ASOPDto {
    paymentTypeRef: string;
    amount: number;
}

export interface OrderBenefit extends ASOPDto {
    benefitsTypeRef: string;
    amount: number;
}

export interface CreateOrderDto extends ASOPDto {
    mealRef: string;
    date: string;
}

export interface INomenclature extends ASOPDto {
    nomenclatureRef: string
    nomenclatureName: string
}

export interface OrderStatusDto {
    name: string;
    value: OrderStatus;
}

export enum OrderStatus {
    SENT = "SENT",
    ALL = "ALL",
    IN_PROCESSING = "IN_PROCESSING",
    IN_PRODUCTION = "IN_PRODUCTION",
    COMPLETED = "COMPLETED",
    ORDER = "ORDER",
    CANCELED_BY_CLIENT = "CANCELED_BY_CLIENT",
    CANCELED_BY_OPERATOR = "CANCELED_BY_OPERATOR"
}
