import React, {FC} from 'react';
import {InfoCircleFill} from "react-bootstrap-icons";

interface ErrorBlockProps {
    error?: string
}

const ErrorBlock: FC<ErrorBlockProps> = ({error}) => {

    if (!error)
        return null

    return (
        <>
            <div className="d-flex align-items-center gap-2">
                <InfoCircleFill color="#6ea8ff"/>
                <label>
                    {
                        error
                    }
                </label>
            </div>
        </>
    );
}

export default ErrorBlock;
