import React, {MouseEventHandler, ReactNode} from 'react';
import Button from "react-bootstrap/Button";
import {RFCWithAnyProps} from "../../../common/types";

interface IProps {
    title?: ReactNode;
    svg: any;
    onClick: MouseEventHandler;
    children?: React.ReactNode
}

const SvgButton: RFCWithAnyProps<IProps> = ({title, svg, onClick, ...props}) => {
    return (
        <Button
            {...props}
            onClick={onClick}>
            {title}
            {svg}
            {props.children}
        </Button>
    );
};

export default SvgButton;
