import {useEffect, useMemo, useState} from "react";
import {useDebouncedCallback} from 'use-debounce';

interface IUseResizeWindow {
    width: number
    isMobileWidth: boolean
}

const useResizeWindow = (): IUseResizeWindow => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', debouncedResize);
        return () => {
            window?.removeEventListener('resize', debouncedResize);
        }
    }, []);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const debouncedResize = useDebouncedCallback(handleWindowSizeChange, 500)

    const isMobileWidth = useMemo(() => width < 768 , [width])

    return {
        width, isMobileWidth
    }
}

export default useResizeWindow
