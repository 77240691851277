import {IOptionSelect} from "../components/UI/select/LoadableSelect";
import {TAdvancedMenuItem} from "../components/cards/nomenclature/NomenclatureCards";
import ComplexSet from "../entity/complexSet/ComplexSet";
import CartItemDto from "../entity/cart/CartItemDto";

export const SHOW_FOR_TIME_MS = 900000
export const MINUTE = 60000
export const SECOND = 1000

export default class DateUtils {

    public static dateToApi(date: Date | null): string {
        if (date instanceof Date) {
            return ""
                + (date.getFullYear() * 10000 +
                    (date.getMonth() + 1) * 100
                    + date.getDate())
        }
        return "";
    }

    public static dateToDashFormat(date: Date | null): string {
        if (date instanceof Date)
            return date.toISOString().split('T')[0]

        return "";
    }

    public static handleDates = (body: any): any => {
        if (body === null || body === undefined || typeof body !== "object")
            return body;

        for (const key of Object.keys(body)) {
            const value = body[key];
            if (this.isIsoDateString(value))
                body[key] = new Date(value);
            else if (typeof value === "object")
                this.handleDates(value);
        }
    }

    private static isoDateFormat: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

    private static isIsoDateString = (value: any): boolean => {
        return value && typeof value === "string" && this.isoDateFormat.test(value);
    }

    public static stringDateToLocal(date: string) {
        return new Date(date).toLocaleDateString()
    }

    public static getFullYear(): number {
        return new Date().getFullYear()
    }

    public static years(count: number = 5): number[] {
        let year = DateUtils.getFullYear()
        return [...Array(count)].map((v, i) => year - i)
    }

    public static months = (): string[] => {
        let year = DateUtils.getFullYear()
        return [...Array(12)].map((v, i) => new Date(year, i).toLocaleString('ru', {month: 'long'}))
    }

    public static monthOptions = (): IOptionSelect[] => {
        let year = DateUtils.getFullYear()
        return [...Array(12)].map((v, i) => {
            let date = new Date(year, i)
            return {
                label: date.toLocaleString('ru', {month: 'long'}),
                value: date.toLocaleString('en-US', {month: 'long'}).toUpperCase()
            }
        })
    }

    public static isComplex(complex: any): boolean {
        return itemIsComplexSet(complex)
    }

    public static isEditComplex(complex: any): boolean {
        return itemIsComplexSet(complex) && !complex?.fixed
    }

    public static isEditComplexInCart(complex: CartItemDto): boolean {
        return complex.nomenclature.complex &&  !complex.nomenclature.complexData?.fixed
    }

    public static  isClosedOfTime(allowedUntil: Date){
        const now = new Date()
        const timeTmp = allowedUntil.getTime() - now.getTime()
        return timeTmp > 0 && timeTmp <= SHOW_FOR_TIME_MS;
    }
}

export function itemIsComplexSet(object: TAdvancedMenuItem): object is ComplexSet {
    return "fixed" in object;
}

