import {ComplexSetItem} from "../entity/complexSet/ComplexSet";
import {OrderItem} from "../entity/order/OrderDto";
import AvailableBenefitDto from "../entity/client/AvailableBenefitDto";
import {INotificationDto, TYPE_NOTIFICATION} from "../entity/notification";

export type AllKeys<T> = T extends any ? keyof T : never;

export default class DataFormat {
    static selectedFields = ['out', 'calories', 'proteins', 'fats', 'carbs']

    static digitRange(value: number | string) {
        if (typeof value === 'string')
            return Intl.NumberFormat().format(Number(value))

        return Intl.NumberFormat().format(value)
    }

    static stringValueOfRUB(value: number | string): string {
        return `${this.digitRange(value)} ₽`
    }

    static currencyRUB(value: number | string, skipZero: boolean = true) {
        if (Number(value) === 0 && !skipZero)
            return "Бесплатно"
        return value ? `${this.digitRange(value)} ₽` : ''
    }

    static availableFormat(value: AvailableBenefitDto, field: AllKeys<Pick<AvailableBenefitDto, "amountMonthAllowed" | "amountAllowed" | "quantityMonthAllowed" | "quantityAllowed">>) {
        if (value.quantitative) {
            if (field === "amountAllowed")
                return `${this.digitRange(value["quantityAllowed"])} шт.`

            if (field === "amountMonthAllowed")
                return `${this.digitRange(value["quantityMonthAllowed"])} шт.`

            return `${this.digitRange(value[field])} шт.`
        } else
            return `${this.digitRange(value[field])} ₽`
    }

    static roundTOInt(value: number | string): number {
        return Number(value)
    }

    static formatToGrams(value: number | string): string {
        if (!value)
            return ""

        return `${value} г`
    }

    static formatToCalories(value: number | string): string {
        if (!value)
            return ""

        return `${value} ккал`
    }

    static strArrayToObject(array: string[], splitValue: string = '=') {
        return array.reduce((prev, cur) => {
            const [k, v] = cur.split(splitValue)
            return {...prev, [k]: v}
        }, {})
    }

    static calcPriceComplex(orderItems: OrderItem[], complexItems?: ComplexSetItem[]) {
        return complexItems?.length ?
            complexItems.reduce((prev, cur) => {
                const foundOrderItem = orderItems?.find(elem => elem.nomenclatureRef === cur.nomenclature.ref)
                return foundOrderItem ? foundOrderItem.price + prev : prev
            }, 0) : 0
    }

    static isEmpty(obj: any) {
        let checkEmpty = Object.keys(obj).length === 0

        if (checkEmpty)
            return checkEmpty

        if (!checkEmpty) {
            return !Object.keys(obj).some((cur: string) => {
                return obj[`${cur}`]?.length > 0
            }, false)
        }
    }

    static toUpperFirstChart(str: string){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    static humanizeNutrition(val: string) {
        switch (val) {
            case 'out':
                return "Вес";
            case 'calories':
                return "Калорийность";
            case 'proteins':
                return "Белки";
            case 'fats':
                return "Жиры";
            case 'carbs':
                return "Углеводы";
        }
    }

    static formatPhone(val?: string) {
        if (val)
            return val.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 ($1)-$3-$3-$4');

        return '';
    }

    static pathForNotification(notification: INotificationDto){
        let path = ''
        switch (notification.type) {
            case TYPE_NOTIFICATION.ORDER_UPDATED:
                path = `/orders/${notification.data}`
                break;
            case TYPE_NOTIFICATION.REQUEST_FEEDBACK:
                path = `/orders/${notification.data}`
                break;
            case TYPE_NOTIFICATION.REQUEST_CONTACT_INFO:
                path = `profile`
                break;
            default:
        }

        return path
    }
}
