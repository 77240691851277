import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const IndexPage = () => {
    const navigate = useNavigate();

    useEffect(()=>{
        navigate("/catalog")
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<div/>);
};

export default IndexPage;
