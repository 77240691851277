import AbstractService from "../../common/AbstractService";
import {IAuthLogInParams, IAvailableAuthTypes, IResponseTokens, ITokens, IUserInfo} from "./AuthDto";
import EntityApiResponse from "../../common/dto/EntityApiResponse";
import {api, getTokensLocalStorage} from "../../api/axiosInstances";
import ResponseData from "../../common/dto/EntityApiResponse";
import {AxiosError} from "axios";

export const getAsyncTokensLocalStorage = async (): Promise<ITokens | undefined> => {
    const accessToken = await localStorage.getItem('accessToken')
    const refreshToken = await localStorage.getItem('refreshToken')

    if (accessToken && refreshToken)
        return {token: accessToken, refreshToken}
}


export default class AuthService extends AbstractService {
    static async logIn(data: IAuthLogInParams): Promise<EntityApiResponse<IResponseTokens>> {
        console.log(data)
        const response = await api.post<EntityApiResponse<IResponseTokens>>('/auth/login', data, {})
        console.log(response)
        return response.data
    }

    static async getTokens(refreshToken: string): Promise<EntityApiResponse<IResponseTokens>> {
        const response = await api.post<EntityApiResponse<IResponseTokens>>('/auth/refreshToken',
            {refreshToken}
        )
        return response.data
    }

    static async refreshTokens(): Promise<EntityApiResponse<IResponseTokens>> {
        const tokens = getTokensLocalStorage()
        const response = await api.post<EntityApiResponse<IResponseTokens>>('/auth/refreshToken',
            {refreshToken: tokens?.refreshToken}
        )
        return response.data
    }

    static async getUserInfo(): Promise<ResponseData<IUserInfo>> {
        const response = await api.get<EntityApiResponse<IUserInfo>>('/auth/userInfo')
        return response.data
    }

    static async getAvailableTypes(debug: string): Promise<ResponseData<IAvailableAuthTypes>> {
        const response = await api.get<EntityApiResponse<IAvailableAuthTypes>>('/auth/availableTypes', {
            params: {
                debug
            }
        })
        return response.data
    }

    static async getUserInfo2(): Promise<ResponseData<IUserInfo>> {
        const response = await api.get<EntityApiResponse<IUserInfo>>('/auth/userInfo')

        if (response instanceof AxiosError) {
            const resp = response as AxiosError
            return {...response.data, status: resp.response?.status}
        }

        return {...response.data, status: response.status}
    }
}
