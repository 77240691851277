import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import {enableMapSet} from "immer"
import {AbstractSate} from "./AbstractSate";
import CartDto from "../../entity/cart/CartDto";
import CartItemIncoming, {
    CartComplexSetItem,
    CartItemIncomingComplexConstructor, ItemIncomingComplexConstructor
} from "../../entity/cart/CartItemIncoming";
import DateUtils from "../../utils/DateUtils";

enableMapSet()

const name = 'carts'

interface CartState extends AbstractSate {
    isLoading: boolean
    carts: CartDto[]
    cart?: CartDto
    complexConstructor?: CartItemIncomingComplexConstructor
    countItemsInToCart: number
    isShowBanner: boolean
}

const initialState: CartState = {
    isLoading: true,
    error: null,
    countItemsInToCart: 0,
    carts: [],
    cart: undefined,
    isShowBanner: false
}

export const cartSlice = createSlice({
    name,
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setCarts: (state, action: PayloadAction<CartDto[]>) => {
            const {payload} = action
            state.carts = payload.filter(i => i.items.length > 0)
            state.isLoading = false
        },
        setCart: (state, action: PayloadAction<CartDto | undefined>) => {
            const {payload} = action
            let carts = state.carts

            if (payload) {
                if (payload.items.length) {
                    if (!carts.length)
                        carts = [payload]
                    else {
                        carts = carts.map(cart => cart.ref === payload?.ref ? payload : cart)
                    }
                } else {
                    carts = carts.filter(cart => cart.ref !== payload?.ref)
                }

                state.cart = payload
                state.carts = carts
                state.countItemsInToCart = payload?.items?.length ?? 0

                state.isShowBanner = DateUtils.isClosedOfTime(payload.allowedUntil);
            }
        },
        setCountItemsInCart: (state, action: PayloadAction<number>) => {
            state.countItemsInToCart = action.payload
        },
        incCountItemsInCart: state => {
            // console.log('inc')
            state.countItemsInToCart += 1
        },
        setShowBannerCart: (state, action) => {

        },
        initialComplexConstructor: (state, action) => {
            state.complexConstructor = action.payload
        },
        addToComplexSet: (state, action: PayloadAction<ItemIncomingComplexConstructor>) => {
            const complexConstructor = state.complexConstructor
            let items = complexConstructor?.cartComplexSetItems

            if (!items)
                items = []

            const foundItems = items.filter(i => i.refCategory === action.payload.refCategory)
            if (action.payload.isChecked) {
                if (!foundItems.length) {
                    items.push(action.payload)
                } else {
                    const first = foundItems[0]
                    const {countRequireInCategory} = first

                    if (countRequireInCategory === foundItems.length) {
                        items = [...items.filter(i => i.nomenclatureRef !== first.nomenclatureRef), action.payload]
                    } else
                        items.push(action.payload)
                }
            } else {
                items = [...items.filter(i => i.nomenclatureRef !== action.payload.nomenclatureRef)]
            }

            if (state.complexConstructor)
                state.complexConstructor.cartComplexSetItems = items;
        },
        setShowBanner: (state, action) => {
            state.isShowBanner = action.payload;
        }
    }
})

export const {
    setCarts,
    setCart,
    incCountItemsInCart,
    setCountItemsInCart,
    addToComplexSet,
    setShowBanner,
    initialComplexConstructor
} = cartSlice.actions
export default cartSlice.reducer

