import React, {FC} from 'react';
import './menu.scss'
import {NavLink} from "react-router-dom";
import {BoxArrowLeft,} from "react-bootstrap-icons";
import NavDropdown from "react-bootstrap/NavDropdown";
import {clearTokensLocalStorage} from "../../api/axiosInstances";
import {Page} from "../../common/types";
import Notifications from '../notifications/Notifications';
import {useAppSelector} from "../../redux/hooks";
import {selectCountUnreadNotifications} from "../../redux/selectors/selectors";

interface ToolbarMenuProps {
    pages: Page[];
}

const ToolbarMenu: FC<ToolbarMenuProps> = ({pages}) => {
    const countNotifications = useAppSelector(state => selectCountUnreadNotifications(state))

    const logoutHandle = () => {
        clearTokensLocalStorage()
        window.location.replace('/auth')
    }

    return (
        <div className="toolbarMenu">
            {
                pages.filter(i => !(i.notInMenu ?? false)).sort((a, b) => a.id - b.id)
                    .map(p => {
                        if (p.path.includes("profile"))
                            return <NavDropdown key={p.path} className="link exp"
                                                title={(<div className="d-inline-flex">
                                                    <div className="d-flex flex-column align-items-center">
                                                        {
                                                            p.icon
                                                        }
                                                        <span className="wrapTitle">Профиль</span>
                                                    </div>
                                                </div>)}>
                                <NavDropdown.Item href="#">
                                    <NavLink className="link option" to="/profile">
                                        {
                                            p.icon
                                        }
                                        <span className="wrapTitle">{p.name}</span>
                                    </NavLink>
                                </NavDropdown.Item>
                                <NavDropdown.Item as={"li"} href="#" onClick={logoutHandle}>
                                    <div className="link option">
                                        <BoxArrowLeft/>
                                        <span className="wrapTitle">Выход</span>
                                    </div>
                                </NavDropdown.Item>
                            </NavDropdown>

                        if (p.path.includes('catalog'))
                            return <NavLink key={p.path} className="link exp" to={p.path + '#divider-main-banner'}>
                                {
                                    p.icon
                                }
                                <span className="wrapTitle">{p.name}</span>
                            </NavLink>

                        return <NavLink key={p.path} className="link exp" to={p.path}>
                            {
                                p.icon
                            }
                            <span className="wrapTitle">{p.name}</span>
                        </NavLink>
                    })
            }
            <Notifications countNotifications={countNotifications}/>
        </div>
    );
}

export default ToolbarMenu;
