import React from "react";

export const mainMenuButtonSvg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" fill="currentColor" viewBox="0 0 18 12">
    <path d="M18 12H0v-2h18v2Zm0-5H0V5h18v2Zm0-5H0V0h18v2Z"/>
</svg>;

export const closeMenuButtonSvg = <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" fill="currentColor" viewBox="0 0 43 43">
    <path  strokeWidth="4" d="M10.44 31.652 31.893 10.68m-21.933.574 21.307 21.119"/>
</svg>

export const cartSvg = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                            aria-hidden="true"
                            className="bi bi-bag" viewBox="0 0 16 16">
    <path
        d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
</svg>

export const closeMenuSvg = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-x-lg" viewBox="0 0 16 16">
    <path
        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
</svg>
