import React, {FC} from "react";
import "./style.scss"
import Logo from "./Logo";

interface IProps {
    rightElements?: React.ReactNode
    leftElements?: React.ReactNode
}

const Toolbar: FC<IProps> = ({rightElements, leftElements}) => {
    return (
        <header className="navbar toolbar">
            <div className="blur"/>
            <div className="wrapElements">
                <div className="logoWrap">
                    <Logo/>
                </div>
                {leftElements}
                {rightElements}
            </div>

        </header>
    )
}

export default Toolbar
