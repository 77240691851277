import React, {FC, useEffect} from 'react';
import {useFetching} from "../../hooks/useFetching";
import ClientProfileService from "../../entity/clientProfile/ClientProfileService";
import {SingleValue} from "react-select";
import LoadableSelect, {IOptionSelect} from "../UI/select/LoadableSelect";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {clearData, setCurrentDivision, setDivisions} from "../../redux/reducers/divisionSlice";
import {divisionSorted} from "../../redux/selectors/selectors";

export enum DivisionSortType {
    ALL,
    ACTIVE
}

interface DivisionSelectorProps {
    sorted?: DivisionSortType,
    className?: string | undefined
}

const DivisionSelector: FC<DivisionSelectorProps> = ({sorted = DivisionSortType.ACTIVE, className}) => {
    const dispatch = useAppDispatch()
    const {personnelNumber} = useAppSelector(state => state.profileReducer)
    const {currentDivisionRef} = useAppSelector(state => state.divisionReducer)
    const divisions = useAppSelector(state => divisionSorted(state, sorted))

    const [fetchDivisions, isDivisionsLoading, divisionsError] = useFetching<string>
    (async (personnelNumber) => {
        const response = await ClientProfileService.getProfile(personnelNumber);
        dispatch(setDivisions(response.body.divisions.filter(d => (sorted === DivisionSortType.ACTIVE) ? d.active : true)))
    })

    useEffect(() => {
        fetchDivisions(personnelNumber).then(null)
    }, [])


    const divisionChangeHandler = (division: SingleValue<IOptionSelect>) => {
        const foundDivision = divisions.find(d => d.divisionRef === division?.value)

        if (foundDivision) {
            if (sorted !== DivisionSortType.ALL)
                dispatch(clearData())
            dispatch(setCurrentDivision(foundDivision))
        }
    }

    return (
        <LoadableSelect data-testid={'divisions'}
            className={className}
            value={currentDivisionRef}
            label={"Площадка"}
            placeholder={"Загрузка..."}
            onChange={divisionChangeHandler}
            options={divisions.map(d => ({label: d.divisionName, value: d.divisionRef}))}
            loading={isDivisionsLoading}
            error={divisionsError}/>
    );
};

export default DivisionSelector;
