import React, {FC, memo} from 'react';
import Image from "react-bootstrap/Image";
import logo from "../../assets/logo.png?as=webp";
import {Link} from "react-router-dom";
import './style.scss'

interface LogoProps {
    height?: number
}

const Logo: FC<LogoProps> = ({height}) => {
    return (
        <Link to={'/'}>
            <Image className="mainLogo" src={logo} alt="logo" loading="lazy" height={height ? height : undefined}/>
        </Link>
    );
}

export default memo(Logo);
