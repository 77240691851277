import React, {useEffect, useState} from 'react';
import {NavLink, Outlet} from "react-router-dom";
import RightMenu from "../components/toolbar/RightMenu";
import {RFC, Page} from "../common/types";
import "./layout.scss"
import Toolbar from "../components/toolbar";
import ConfirmDialogProvider from "../components/dialogs/ConfirmDialogContext";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import Container from "react-bootstrap/Container";
import AuthService from "../entity/auth/AuthService";
import FullPageLoading from "../components/UI/loader/FullPageLoading";
import {useAppDispatch} from "../redux/hooks";
import {setPersonalNumber} from "../redux/reducers/profileSlice";
import InfoCartBanner from "../components/banners/InfoCartBanner";
import ToolbarMenu from "../components/toolbar/ToolbarMenu";
import logo from "../components/toolbar/Logo";

interface IProps {
    pages: Page[];
}

const Layout: RFC<IProps> = ({pages}) => {
    const dispatch = useAppDispatch()
    const [status, setStatus] = useState<number | undefined>()

    useEffect(() => {
        AuthService.getUserInfo2().then(resp => {
            console.log(resp)
            if (resp?.status)
                setStatus(resp.status)

            if (resp.body) {
                dispatch(setPersonalNumber(resp.body.personnelNumber))
            }
        });
    }, [])

    return (
        <>
            {
                (status !== 200 || !status) ? <FullPageLoading/> :
                    <ConfirmDialogProvider>
                        <Container className="mainContainer">
                            <InfoCartBanner/>
                            <Toolbar rightElements={<RightMenu id="menu" pages={pages} placement={"end"} name={"menu"}/>}
                                leftElements={<ToolbarMenu pages={pages}/>}/>
                            <Outlet/>
                        </Container>
                        <div className="appFooter">
                            <NavLink to="contacts">Контакты</NavLink>
                            <NavLink to="help#about">Вопросы и ответы</NavLink>
                        </div>
                        <ConfirmDialog/>
                    </ConfirmDialogProvider>
            }
        </>
    );
};

export default Layout;
