import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {ITokens} from "../../entity/auth/AuthDto";
import {getAsyncTokensLocalStorage} from "../../entity/auth/AuthService";
import LocalStorageService from "../../entity/storage/LocalStorageService";

interface IAuth {
    isAuth: boolean
    accessToken?: string | null
    refreshToken?: string | null
}

interface IAuthContext {
    auth: IAuth,
    setAuthData: (data: ITokens) => void
}

const defaultContext: IAuthContext = {
    auth: {
        isAuth: false
    },
    setAuthData: () => {
    }
}

export const AuthContext = createContext<IAuthContext>(defaultContext)

export const useAuth = () => useContext(AuthContext)

interface AuthProviderProps {
    children: React.ReactNode
}

const AuthProvider: FC<AuthProviderProps> = ({children, ...props}) => {
    const [auth, setAuth] = useState<IAuth>({isAuth: false})

    useEffect(() => {
        initialCall().then(null)
    }, [])

    const initialCall = async () => {
        const tokens = await getAsyncTokensLocalStorage()

        if (tokens){
            const {token: accessToken, refreshToken} = tokens
            setAuth({
                accessToken, refreshToken, isAuth: false
            })
        }
    }

    const setAuthData = (data: ITokens) => {
        LocalStorageService.setValue('accessToken', data.token)
        LocalStorageService.setValue('refreshToken', data.refreshToken)
        setAuth({...data, isAuth: true})
    }

    return (
        <AuthContext.Provider value={{auth, setAuthData}} {...props}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;